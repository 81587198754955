export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_CATEGORIES':
      return {
        ...state,
        categories: action.payload.data,
      }
    default:
      return state
  }
}
