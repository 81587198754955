export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_TICKETS':
      return {
        ...state,
        tickets: action.payload.data,
      }

    case 'GET_TICKET':
      return {
        ...state,
        ticket: action.payload,
      }
    default:
      return state
  }
}
