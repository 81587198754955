import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../ButtonWeb'
import { AiOutlineClose } from 'react-icons/ai'

const Item = props => {
  const { data } = props
  const { url, alt, link } = data

  const [modal, setModal] = useState(false)

  console.log(data)

  return (
    <Main>
      <Img
        src={url}
        alt={alt}
        onClick={() => setModal(true)}
      />
      <Modal modal={modal}>
        <Icon onClick={() => setModal(false)}>
          <AiOutlineClose />
        </Icon>
        <Img2
          src={url}
          alt={alt}
        />
        {link === null ? (
          <Link href={url} target="blank">
            <Button>Descargar</Button>
          </Link>
        ) : (
          <Link href={link} target="blank">
            <Button>Ir</Button>
          </Link>
        )}
      </Modal>
    </Main>
  )
}

export default Item

const Main = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`

const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const Modal = styled.div`
  position: fixed;
  display: ${({ modal }) => (modal === true ? 'flex' : 'none')};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  padding: 0 15px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
`

const Img2 = styled.img`
  object-fit: contain;
  width: 100%;
  margin-bottom: 50px;
`

const Icon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
`

const Link = styled.a`
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
