import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { create, getAll, setTitle } from '../../actions'
import Card from '../../components/Card'
import Button from '../../components/Button'
import styled from 'styled-components'
import Select from '../../components/Select'
import Swal from 'sweetalert2'

const CreateSupplier = props => {
  const [busId, setBusId] = useState(0)
  const [errorId, setErrorId] = useState(0)
  const { setTitle, buses, user } = props
  const lineId = parseInt(user.lineId)

  useEffect(() => {
    const topbar = {
      title: 'Crear Ticket',
    }
    setTitle(topbar)
    props.getAll('buses', 'GET_BUSES')
    // eslint-disable-next-line
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    if (busId === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selecciona una unidad',
      })
      return
    }
    if (errorId === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selecciona un tipo de incidencia',
      })
      return
    }
    props
      .create('tickets', 'POST_BANNER', {
        bus_id: busId.value,
        error_id: errorId.value,
        lineId,
      })

      .then(() => {
        setBusId(0)
        setErrorId(0)
      })
  }

  if (!buses) {
    return <div>Cargando...</div>
  }

  return (
    <Card title={'Información del ticket'}>
      <form id="formUser" onSubmit={e => handleSubmit(e)}>
        <Select
          options={
            buses
              ? buses
                  .filter(bus =>
                    lineId === 0
                      ? true
                      : parseInt(bus.line_id) === lineId
                  )
                  .map(bus => ({
                    label: bus.name,
                    value: bus.id,
                  }))
              : []
          }
          value={busId}
          onChange={e => setBusId(e)}
          placeholder="Unidad"
        />
        <Select
          options={[
            {
              label:
                'Falla física en equipo (Router) instalado en la unidad',
              value: 1,
            },
            { label: 'Intermitencia o falla en la red', value: 2 },
            { label: 'Saturación de red', value: 3 },
          ]}
          onChange={e => setErrorId(e)}
          value={errorId}
          placeholder="Tipo de incidencia"
        />

        <BarButton>
          <Button type="submit" color="success">
            Crear
          </Button>
          <Link to="/dashboard/tickets">
            <Button className="btn --danger">Cancelar</Button>
          </Link>
        </BarButton>
      </form>
    </Card>
  )
}

const mapStateToProps = state => {
  return {
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
    user: state.reducerApp.user,
    buses: state.buses.buses,
  }
}

const mapDispatchToProps = {
  create,
  getAll,
  setTitle,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSupplier)

const BarButton = styled.div`
  margin-top: 1rem;
`
