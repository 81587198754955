import React from 'react'
import styled from 'styled-components'
import Banner from './Banner'

const Home = () => {
  return (
    <Main id="home">
      <Banner />
    </Main>
  )
}

export default Home

const Main = styled.main``
