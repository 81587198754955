import React from 'react'
import styled from 'styled-components'
import Logo from '../../assets/static/logo_web.svg'

const Header = () => {
  return (
    <HeaderS>
      <Img src={Logo} />
      <Title>¡Gracias por utilizar la red de WiFi Móvil!</Title>
    </HeaderS>
  )
}

export default Header

const HeaderS = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 100vw;
  box-sizing: border-box;
  background: ${props =>
    props.navbar ? '#070B28' : props.theme.white};
  font-family: 'Nunito', sans-serif;
`

const Img = styled.img`
  height: 100px;
`

const Title = styled.h1`
  font-weight: 100;
  color: #797979;
  font-size: 16px;
  text-align: center;
  margin-top: 35px;
`
