import { BsGear } from 'react-icons/bs'
import { AiOutlineHome } from 'react-icons/ai'
import { FiAlertTriangle, FiImage } from 'react-icons/fi'
import { FaBus } from 'react-icons/fa'

const role = parseInt(sessionStorage.getItem('role'))

let menu = []

if (role <= 1) {
  menu = [
    {
      id: 1,
      title: 'Inicio',
      icon: <AiOutlineHome />,
      link: '/dashboard',
    },
    {
      id: 2,
      title: 'Banners',
      icon: <FiImage />,
      link: '/dashboard/banners',
    },
    {
      id: 6,
      title: 'Unidades',
      icon: <FaBus />,
      isOpen: false,
      link: '/dashboard/buses',
    },
    {
      id: 3,
      title: 'Tickets',
      icon: <FiAlertTriangle />,
      isOpen: false,
      link: '/dashboard/tickets',
    },
    {
      id: 4,
      title: 'Configuración',
      icon: <BsGear />,
      isOpen: false,
      submenu: [
        {
          id: 1,
          title: 'Usuarios',
          isOpen: false,
          link: '/dashboard/users',
        },
      ],
    },
  ]
} else {
  menu = [
    {
      id: 6,
      title: 'Unidades',
      icon: <FaBus />,
      isOpen: false,
      link: '/dashboard',
    },
    {
      id: 3,
      title: 'Tickets',
      icon: <FiAlertTriangle />,
      isOpen: false,
      link: '/dashboard/tickets',
    },
  ]
}

export default menu
