import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Item from '../../components/Item'

const Banner = props => {
  const { banners } = props

  useEffect(() => {
    props.getAll('banners', 'GET_BANNERS')
    //eslint-disable-next-line
  }, [])

  if (!banners) {
    return <h1>Cargando...</h1>
  }

  const demo = {
    url: `https://via.placeholder.com/1200x300.png`,
    alt: 'Banner 1',
    link: 'https://www.google.com',
  }

  const data = banners.map(b => ({
    ...b,
    url: `${process.env.REACT_APP_API}public/banners/${b.url}`,
  }))

  return (
    <Main>
      <Container>
        <Item data={data[0] !== undefined ? data[0] : demo} />
      </Container>
      <Container2>
        <Item data={data[1] !== undefined ? data[1] : demo} />
      </Container2>
      <Container3>
        <Item data={data[2] !== undefined ? data[2] : demo} />
      </Container3>
      <Grid>
        <Item data={data[3] !== undefined ? data[3] : demo} />
        <Item data={data[4] !== undefined ? data[4] : demo} />
        <Item data={data[5] !== undefined ? data[5] : demo} />
      </Grid>
      <Grid2>
        <Item data={data[6] !== undefined ? data[6] : demo} />
        <Item data={data[7] !== undefined ? data[7] : demo} />
        <Item data={data[8] !== undefined ? data[8] : demo} />
        <Item data={data[9] !== undefined ? data[9] : demo} />
      </Grid2>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Banner)

const Main = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  color: #000;
  padding-bottom: 15px;
`

const Container = styled.div`
  width: 100vw;
  padding: 0 15px;
  margin: 0;
  box-sizing: border-box;
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
`
const Container2 = styled(Container)`

`
const Container3 = styled(Container)`

`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  box-sizing: border-box;
  padding: 0 15px;
  height: calc(100vw / 3);
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
`

const Grid2 = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
  height: calc(100vw / 4);
`
