export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_BUSES':
      return {
        ...state,
        buses: action.payload.data,
      }

    default:
      return state
  }
}
