import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Profile from '../../components/Profile'
import {
  BsMoon,
  BsSun,
  BsChatLeft,
  BsFullscreenExit,
  BsThreeDotsVertical,
} from 'react-icons/bs'
import { IoMenuOutline } from 'react-icons/io5'
import ButtonCircle from '../../components/ButtonCircle'
import { Link } from 'react-router-dom'
import logo from '../../assets/static/logo.svg'
import logo_white from '../../assets/static/logo_white.svg'
import logo_m from '../../assets/static/logo_m.svg'
import logo_m_white from '../../assets/static/logo_m_white.svg'

const Footer = props => {
  const {
    onTheme,
    theme,
    toggleBarButton,
    toggleBar,
    toggleBarMouse,
  } = props
  const fullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      document.documentElement.requestFullscreen()
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const finalLogo = !theme ? logo : logo_white

  const finalLogo2 = !theme
    ? !toggleBar || toggleBarMouse
      ? logo
      : logo_m
    : !toggleBar || toggleBarMouse
    ? logo_white
    : logo_m_white

  return (
    <Topbar toggleBar={toggleBar} toggleBarMouse={toggleBarMouse}>
      <Menu>
        <LogoContainer toggleBar={toggleBar}>
          <Link to={'/'}>
            <Logo src={finalLogo2} alt="logo" />
          </Link>
        </LogoContainer>
        <MenuMin onClick={toggleBarButton}>
          <IoMenuOutline />
        </MenuMin>
        <MobileTopBar>
          <Logo src={finalLogo} alt="logo" />
        </MobileTopBar>
        <MobileTopBar>
          <ButtonCircle onClick={() => setIsOpen(!isOpen)}>
            <BsThreeDotsVertical />
          </ButtonCircle>
        </MobileTopBar>
      </Menu>
      <ButtonBar isOpen={isOpen}>
        <ButtonCircle onClick={onTheme}>
          {theme ? <BsSun /> : <BsMoon />}
        </ButtonCircle>
        <ButtonCircle onClick={() => fullScreen()}>
          <BsFullscreenExit />
        </ButtonCircle>
        {/* Notificaciones */}
        <ButtonCircle>
          <BsChatLeft />
        </ButtonCircle>
        <Profile />
      </ButtonBar>
    </Topbar>
  )
}

export default connect(null, null)(Footer)

const Topbar = styled.div`
  z-index: 9999;
  grid-area: topbar;
  position: fixed;
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundButton};
  box-sizing: border-box;
  border: ${({ theme }) => theme.border};
  border-collapse: collapse;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100vw;
    margin-right: 40px;
    position: relative;
  }
`

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  @media (max-width: 768px) {
    transition: all 0.2s linear 0ms;
    position: absolute;
    background-color: ${({ theme }) => theme.backgroundButton};
    bottom: -100%;
    left: 0;
    width: 100vw;
    justify-content: space-around;
    box-size: border-box;
    height: 75px;
    border: ${({ theme }) => theme.border};
    transform-origin: 0 0 0;
    ${props =>
      props.isOpen
        ? `
   transform: scaleY(1);
    
    `
        : 'transform: scaleY(0)'}
  }
`
const MobileTopBar = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
const Logo = styled.img`
  height: 36px;
`

const LogoContainer = styled.div`
  width: ${props => (props.toggleBar ? '80px' : '280px')};
  transition: all 0.2s linear 0ms;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`

const MenuMin = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
