import { combineReducers } from 'redux'
import reducerApp from './reducer'
import users from '../pages/Users/reducer'
import categories from '../pages/Categories/reducer'
import banners from '../pages/Banners/reducer'
import products from '../pages/Products/reducer'
import contact from '../pages/Contact/reducer'
import buses from '../pages/Buses/reducer'
import tickets from '../pages/Tickets/reducer'

const reducer = combineReducers({
  reducerApp,
  users,
  categories,
  banners,
  products,
  contact,
  buses,
  tickets,
})

export default reducer
