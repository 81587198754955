import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { FiChevronDown } from 'react-icons/fi'
import menu from '../../utils/menu'

const AsideComponent = props => {
  const {
    toggleBar,
    toggleBarMouse,
    toggleMouseHover,
    toggleBarButton,
  } = props
  const location = useLocation()
  const [menus, setMenus] = useState([])
  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    setMenus(menu)

    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
    // eslint-disable-next-line
  }, [])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const handleOpen = (id, isOpen) => {
    setMenus(
      menus.map(category => {
        if (category.id === id) {
          category.isOpen = !isOpen
        }
        return category
      })
    )
  }

  const handleSubmenuOpen = (menuId, submenuId, isOpen) => {
    setMenus(
      menus.map(menu => {
        if (menu.id === menuId) {
          const newMenu = {
            ...menu,
            submenu: menu.submenu.map(submenu => {
              if (submenu.id === submenuId) {
                submenu.isOpen = !isOpen
              }
              return submenu
            }),
          }

          return newMenu
        }
        return menu
      })
    )
  }

  return (
    <Aside
      toggleBar={toggleBar}
      toggleBarMouse={toggleBarMouse}
      onMouseEnter={toggleMouseHover}
      onMouseLeave={toggleMouseHover}
    >
      <Menu>
        <Ul
          toggleBar={toggleBar}
          toggleBarMouse={
            windowSize.innerWidth > 781 ? toggleBarMouse : false
          }
        >
          <Category
            toggleBar={toggleBar}
            toggleBarMouse={
              windowSize.innerWidth > 781 ? toggleBarMouse : false
            }
          >
            Menú
          </Category>
          {menus.map((menu, index) =>
            menu.submenu ? (
              <li key={index}>
                <Li
                  onClick={() => handleOpen(menu.id, menu.isOpen)}
                  className={
                    menu.submenu.find(s =>
                      location.pathname.includes(s.link)
                    ) !== undefined
                      ? 'active'
                      : ''
                  }
                >
                  <div>
                    {menu.icon}
                    <Span
                      toggleBar={toggleBar}
                      toggleBarMouse={
                        windowSize.innerWidth > 781
                          ? toggleBarMouse
                          : false
                      }
                    >
                      {menu.title}
                    </Span>
                  </div>
                  <FiChevronDown />
                </Li>
                <SubMenu
                  isOpen={menu.isOpen}
                  toggleBar={toggleBar}
                  toggleBarMouse={
                    windowSize.innerWidth > 781
                      ? toggleBarMouse
                      : false
                  }
                >
                  {menu.submenu.map((submenu, i) =>
                    submenu.submenu ? (
                      <li key={i}>
                        <Li2
                          onClick={() =>
                            handleSubmenuOpen(
                              menu.id,
                              submenu.id,
                              submenu.isOpen
                            )
                          }
                          className={
                            menu.submenu.find(s =>
                              location.pathname.includes(s.link)
                            ) !== undefined
                              ? 'active'
                              : ''
                          }
                        >
                          <div>
                            {submenu.icon}
                            <Span
                              toggleBar={toggleBar}
                              toggleBarMouse={
                                windowSize.innerWidth > 781
                                  ? toggleBarMouse
                                  : false
                              }
                            >
                              {submenu.title}
                            </Span>
                          </div>
                          <FiChevronDown />
                        </Li2>
                        <SubMenu2
                          isOpen={submenu.isOpen}
                          toggleBar={toggleBar}
                          toggleBarMouse={
                            windowSize.innerWidth > 781
                              ? toggleBarMouse
                              : false
                          }
                        >
                          {submenu.submenu.map((s, i) => (
                            <li key={i}>
                              <SubLink
                                to={s.link}
                                onClick={() =>
                                  windowSize.innerWidth > 781
                                    ? null
                                    : toggleBarButton(false)
                                }
                                className={
                                  s.link === location.pathname
                                    ? 'active'
                                    : ''
                                }
                              >
                                {s.title}
                              </SubLink>
                            </li>
                          ))}
                        </SubMenu2>
                      </li>
                    ) : (
                      <li key={i}>
                        <SubLink
                          to={submenu.link}
                          onClick={() =>
                            windowSize.innerWidth > 781
                              ? null
                              : toggleBarButton(false)
                          }
                          className={
                            submenu.link === location.pathname
                              ? 'active'
                              : ''
                          }
                        >
                          {submenu.title}
                        </SubLink>
                      </li>
                    )
                  )}
                </SubMenu>
              </li>
            ) : (
              <li key={index}>
                <LinkStyled
                  toggleBar={toggleBar}
                  toggleBarMouse={
                    windowSize.innerWidth > 781
                      ? toggleBarMouse
                      : false
                  }
                  to={menu.link}
                  onClick={() =>
                    windowSize.innerWidth > 781
                      ? null
                      : toggleBarButton(false)
                  }
                  className={
                    menu.link === location.pathname ? 'active' : ''
                  }
                >
                  <div>
                    {menu.icon}
                    <Span
                      toggleBar={toggleBar}
                      toggleBarMouse={
                        windowSize.innerWidth > 781
                          ? toggleBarMouse
                          : false
                      }
                    >
                      {menu.title}
                    </Span>
                  </div>
                </LinkStyled>
              </li>
            )
          )}
        </Ul>
      </Menu>
    </Aside>
  )
}

export default AsideComponent

const Aside = styled.aside`
  grid-area: aside;
  position: fixed;
  width: ${props => (props.toggleBar ? '80px' : '280px')};
  height: 100vh;
  background-color: ${({ theme }) => theme.backgroundButton};
  border: ${({ theme }) => theme.border};
  border-collapse: collapse;
  z-index: 999;
  margin-top: 75px;
  padding: 0 24px;
  box-sizing: border-box;
  ${props =>
    props.toggleBar
      ? `
  &:hover{
    width: 270px;
  }
`
      : null}

  @media (max-width: 768px) {
    width: 270px;
    margin-top: 75px;
    z-index: 99;
    transform: ${props =>
      props.toggleBar ? 'translateX(0)' : 'translateX(-100%)'};
  }
`

const Menu = styled.div`
  @media (max-width: 768px) {
    margin-top: 0;
  }
  overflow-y: scroll;
  height: calc(100vh - 77px);
`

const Span = styled.span`
  ${props =>
    props.toggleBar && !props.toggleBarMouse ? 'display: none' : ''};

  margin-left: 10px;

  @media (max-width: 768px) {
    display: inline-block;
  }
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 0;
  padding: 0;
  @media (max-width: 768px) {
    padding: 10px 15px 0 0px;
  }
`

const Category = styled.li`
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 0.75rem 0;
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
  white-space: nowrap;
  position: relative;

  ${props =>
    props.toggleBar && !props.toggleBarMouse ? 'display: none' : ''};
  @media (max-width: 768px) {
    display: inline-block;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  display: block;
  line-height: 2.5rem;
  height: 2.4rem;
  padding: 0 1.5rem 0 45px;
  position: relative;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.text};
  white-space: nowrap;
  transition: all 0.2s;
  margin: 0.1rem 0;
  svg {
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
    transition: color 300ms;
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
  &:hover {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
`

const Li = styled.div`
  text-decoration: none;
  display: block;
  line-height: 2.5rem;
  height: 2.4rem;
  padding: 0 1.5rem 0 45px;
  position: relative;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.text};
  white-space: nowrap;
  transition: all 0.2s;
  margin: 0.1rem 0;
  svg {
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
    transition: color 300ms;
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
  &:hover {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
`

const SubMenu = styled.ul`
  display: ${props =>
    props.toggleBar && !props.toggleBarMouse ? 'none' : ''};
  list-style: none;
  margin-left: 6px;
  transition: all 0.2s;
  transition: max-heigth ${({ isOpen }) => (isOpen ? '1s' : '.6s')};
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: 0 0 0;
  @media (max-width: 768px) {
    display: ${props => (props.toggleBar ? 'block' : 'none')};
  }
  &::before {
    content: '';
    height: 100%;
    opacity: 1;
    width: 3px;
    background: #e0f3ff;
    position: absolute;
    left: 20px;
    top: 0;
    border-radius: 15px;
  }
`

const SubLink = styled(Link)`
  text-decoration: none;
  display: block;
  line-height: 2.5rem;
  height: 2.4rem;
  padding: 0 1.5rem;
  position: relative;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.menu_category};
  white-space: nowrap;
  transition: all 0.2s;
  margin: 0.1rem 0;
  svg {
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
    transition: color 300ms;
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
  &:hover {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
`

const Li2 = styled.div`
  text-decoration: none;
  display: block;
  line-height: 2.5rem;
  height: 2.4rem;
  padding: 0 0 0 1rem;
  position: relative;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.menu_category};
  white-space: nowrap;
  transition: all 0.2s;
  margin: 0.1rem 0;
  svg {
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
    transition: color 300ms;
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
  &:hover {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    background: ${({ theme }) => theme.info};
  }
`

const SubMenu2 = styled.ul`
  display: ${props =>
    props.toggleBar && !props.toggleBarMouse ? 'none' : ''};

  list-style: none;
  font-size: 0.8rem !important;
  transition: all 0.2s;
  transition: max-heigth ${({ isOpen }) => (isOpen ? '1s' : '.6s')};
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: 0 0 0;
  @media (max-width: 768px) {
    display: ${props => (props.toggleBar ? 'block' : 'none')};
  }

  &::before {
    content: '';
    height: 100%;
    opacity: 1;
    width: 3px;
    background: #e0f3ff;
    position: absolute;
    left: 20px;
    top: 0;
    border-radius: 15px;
  }
`
